@font-face {
  font-family: WixRegular;
  src: url(../public/fonts/WixMadeforDisplay-Regular.ttf);
}

@font-face {
  font-family: WixExtraBold;
  src: url(../public/fonts/WixMadeforText-ExtraBold.ttf);
}

body {
  font-family: WixRegular !important;
  font-size: 12px;
  color: #000 !important;
  background-color: #00b09b !important;
  text-align: justify !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
nav {
  font-family: WixExtraBold !important;
}

.container {
  max-width: 800px !important;
}

.navbar {
  /* background-image: linear-gradient(#005f53 95%, #23282d); */
  background-color: #005f53 !important;
  margin: 0em !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

footer {
  /* background-image: linear-gradient(to top, #005f53 95%, #23282d); */
  background-color: #005f53 !important;
  color: #000;
}

.bi-telegram {
  color: #6c005c;
  font-size: 3em;
}

.bi-telegram:hover {
  color: #fff;
}

a {
  color: #566556;
  text-decoration: none;
}

a:hover {
  color: #ffffff;
}

.nav-link {
  font-size: large;
  color: #000 !important;
}

.nav-item {
  margin-right: 0.5em;
}

.nav-link:hover {
  color: #fff !important;
}

@media (max-width: 900px) {
  .nav-link {
    font-size: 3em;
  }
}

.card {
  background-color: #00b09b !important;
  border: 2px solid #000 !important;
}

.card-header:hover {
  cursor: pointer;
}

.headerText {
  white-space: nowrap;
  font-size: 1.5em;
}

.mailLink {
  text-decoration: none;
  color: #000;
}

.mailLink:hover {
  color: #fff;
}

.headerIcons {
  font-size: 2em;
  color: #000;
}

.headerIcons:hover {
  color: #fff;
}

.footerIcons {
  font-size: 3em;
  color: #000;
}

.footerIcons:hover {
  color: #fff;
}

.nav-bar-container {
  display: flex;
}

@media (max-width: 1200px) {
  .nav-bar-container {
    display: contents;
  }
}
